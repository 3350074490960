import React, { useState, useEffect } from 'react';
import { IconMap } from '../../config/IconMap';
import Cookies from 'js-cookie';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement, PaymentRequestButtonElement } from '@stripe/react-stripe-js';

export default function CartModal({ isOpen, closeModal, cartItems, updateCart, removeItem, appUid, styleGuide, clearCart, handlePaymentModal, currency, country }) {
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [view, setView] = useState('cart');
  const [name, setName] = useState('');  // Mandatory name field
  const [email, setEmail] = useState(''); // Optional email field
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null); // For Apple Pay / Google Pay

  const shouldApplyGST = true; // Example flag for GST logic

  const calculateSubTotal = () => {
    return cartItems.reduce((total, item) => total + item.quantity * item.price, 0).toFixed(2);
  };

  const calculateGST = () => {
    const subTotal = parseFloat(calculateSubTotal());
    return shouldApplyGST ? (subTotal * 0.10).toFixed(2) : 0;
  };

  const calculateTotalWithGST = () => {
    const subTotal = parseFloat(calculateSubTotal());
    const gst = parseFloat(calculateGST());
    const total = subTotal + gst;
    return Math.round(total * 100); // Stripe requires total amount in cents
  };

  useEffect(() => {
    if (stripe && cartItems.length > 0 && currency) {  // Ensure currency is passed
      const pr = stripe.paymentRequest({
        country: country,  
        currency: currency,  
        total: {
          label: 'Total',
          amount: calculateTotalWithGST(), // Total amount including GST in cents
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);  // Only show Apple Pay button if it's available
        } 
      });

      pr.on('paymentmethod', async (event) => {
        try {
          // Confirm the payment with the PaymentMethod received from Apple Pay
          const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: event.paymentMethod.id,
          });

          if (error) {
            console.error("Payment error:", error);
            event.complete('fail');
            setError(error.message);
          } else if (paymentIntent.status === "succeeded") {
            event.complete('success');
            clearCart(); // Clear the cart after successful payment
            handlePaymentModal('success');
            closeModal(); // Close the modal
          }
        } catch (err) {
          console.error("Payment error:", err.message);
          event.complete('fail');
        }
      });
    }
  }, [stripe, cartItems, currency, country, clientSecret]);

  useEffect(() => {
    if (isOpen) {
      setView('cart');
      setClientSecret(null);
      setError(null);

      // Disable background scroll
      document.body.style.overflow = 'hidden';

      setTimeout(() => {
        setIsVisible(true);
      }, 10);
    } else {
      setIsVisible(false);

      // Re-enable background scroll
      document.body.style.overflow = '';

      setTimeout(() => {
        setIsVisible(false);
      }, 300);
    }
  }, [isOpen]);

  const generateUniqueId = () => {
    return `activity-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  };

  const handleCheckout = async (e) => {
    e.preventDefault();
    setLoading(true);

    const csrfToken = Cookies.get('csrftoken');
    const currentUrl = window.location.href;

    try {
      const response = await fetch(`/api/checkout/${appUid}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
        credentials: 'include',
        body: JSON.stringify({
          cartItems: cartItems,
          currentUrl: currentUrl,
        }),
      });

      const data = await response.json();

      if (!data.clientSecret) {
        throw new Error('Client Secret not found in response.');
      }

      setClientSecret(data.clientSecret);
      setView('payment');
    } catch (err) {
      console.error("Error during checkout:", err.message || err);
      setError(err.message || "An error occurred during checkout.");
    } finally {
      setLoading(false);
    }
  };

  const confirmPayment = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setLoading(true);
    setError(null);

    try {
      const cardNumberElement = elements.getElement(CardNumberElement);

      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardNumberElement,
          billing_details: {
            name: name,
            email: email || undefined, // Optional email, add only if not empty
          },
        },
      });

      if (paymentIntent && paymentIntent.status === "succeeded") {

        setTimeout(() => {
          clearCart();
          const existingActivity = JSON.parse(localStorage.getItem(`recentActivity-${appUid}`)) || { config: [] };

          const newActivity = {
            id: generateUniqueId(),
            type: "Payment",
            date: new Date().toISOString().split('T')[0],
          };

          existingActivity.config.push(newActivity);
          localStorage.setItem(`recentActivity-${appUid}`, JSON.stringify(existingActivity));

          closeModal();
          handlePaymentModal('success');
        }, 300);
      } else if (error) {
        console.error("Payment error:", error.message);
        throw new Error(error.message);
      }
    } catch (err) {
      if (err && err.message) {
        console.error("Error during payment confirmation:", err.message);
        setError(err.message);

        setTimeout(() => {
          closeModal();
          handlePaymentModal('fail');
        }, 300);
      }
    } finally {
      setLoading(false);
    }
  };

  // Email validation logic
  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex justify-center items-end">
      <div
        className="absolute inset-0 bg-black bg-opacity-50 transition-opacity duration-300"
        onClick={closeModal}
      ></div>

      <div
        className={`bg-white w-full md:w-1/2 h-[90%] rounded-t-2xl relative z-50 transform transition-transform duration-300 ${isVisible ? 'translate-y-0 overflow-y-auto' : 'translate-y-full'}`}
      >
        <div className="p-4 border-b flex items-center justify-center">
          <button
            onClick={closeModal}
            className="absolute top-3 left-3 text-white font-bold text-2xl rounded-full h-8 w-8 flex items-center justify-center bg-gray-700"
          >
            {React.createElement(IconMap['XMarkIcon'], { className: 'h-5 w-5 text-white', 'aria-hidden': 'true' })}
          </button>
          <h2 className="font-semibold">{view === 'cart' ? 'Cart' : 'Payment'}</h2>
        </div>

        {view === 'cart' && (
          <div className="p-4 overflow-y-auto">
            {cartItems.map((item, index) => (
              <div key={index} className="flex items-center justify-between mb-4">
                <div className="flex items-center">
                  <img src={item.image_url} alt={item.title} className="w-16 h-16 rounded-md mr-4" />
                  <div>
                    <p className="font-semibold">{item.title}</p>
                    <p className="text-sm text-gray-500">${item.price}</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <button className="px-2 py-1 text-gray-500" onClick={() => updateCart(item.sku, item.quantity - 1)} disabled={item.quantity === 1} style={{ opacity: item.quantity === 1 ? 0.5 : 1, cursor: item.quantity === 1 ? 'not-allowed' : 'pointer' }}>
                    -
                  </button>
                  <span className="px-4">{item.quantity}</span>
                  <button className="px-2 py-1 text-gray-500" onClick={() => updateCart(item.sku, item.quantity + 1)}>+</button>
                  <button className="ml-4 text-red-500" onClick={() => removeItem(item.sku)}>
                    {React.createElement(IconMap['TrashIcon'], { className: 'h-5 w-5 text-red-500', 'aria-hidden': 'true' })}
                  </button>
                </div>
              </div>
            ))}

            <div className="mt-4 p-4 rounded-md bg-gray-50">
              <div className="text-base">
                {/* Total */}
                <div className="flex justify-between items-center mb-2">
                  <p className="font-semibold">Total:</p>
                  <p>${calculateSubTotal()}</p>
                </div>

                {/* GST */}
                <div className="flex justify-between items-center">
                  <p className="font-semibold">GST (10%):</p>
                  <p>${calculateGST()}</p>
                </div>
              </div>
            </div>

            <div className="fixed bottom-0 w-full md:w-1/2 left-1/2 transform -translate-x-1/2 px-4 py-3">
              <button
                onClick={handleCheckout}
                disabled={cartItems.length === 0 || loading}  // Disable if no items in cart
                className={`w-full bg-indigo-600 text-white py-3 rounded-full shadow hover:bg-indigo-500 transition ${loading || cartItems.length === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                style={{ backgroundColor: styleGuide?.primary }}
              >
                {loading ? 'Processing...' : 'Checkout'}
              </button>
            </div>
          </div>
        )}

        {view === 'payment' && (
          <form onSubmit={confirmPayment} className="p-4">
            {/* Heading */}
            <h3 className="text-lg font-medium text-gray-900">Your details</h3>
            <p className="text-sm text-gray-500 mb-4">Need a receipt? Please enter your email below.</p>

            {/* Name Field (mandatory) */}
            <label className="block mb-2 text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="w-full p-4 mb-4 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Your name"
            />

            {/* Email Field (optional with validation) */}
            <label className="block mb-2 text-sm font-medium text-gray-700">Email address (Optional)</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`w-full p-4 mb-2 border ${email && !validateEmail(email) ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500`}
              placeholder="Your email"
            />
            {email && !validateEmail(email) && (
              <p className="text-red-500 text-sm">Please enter a valid email address.</p>
            )}

            {/* Total Cost (including GST) */}
            <div className="my-4 py-2 border-t">
              <p className="text-sm font-semibold text-gray-700">
                Total (including GST): ${(calculateTotalWithGST() / 100).toFixed(2)}
              </p>
            </div>

            {/* Card Number */}
            <label className="block mb-2 text-sm font-medium text-gray-700">Card number</label>
            <CardNumberElement className="p-4 mb-4 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500" />

            <div className="flex space-x-4">
              {/* Expiry Date */}
              <div className="flex-1">
                <label className="block mb-2 text-sm font-medium text-gray-700">Expiry date</label>
                <CardExpiryElement className="p-4 mb-4 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500" />
              </div>

              {/* CVC */}
              <div className="flex-1">
                <label className="block mb-2 text-sm font-medium text-gray-700">CVC</label>
                <CardCvcElement className="p-4 mb-4 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500" />
              </div>
            </div>

            {error && <div className="text-red-500 text-sm mt-2 mb-2">{error}</div>}

            <button
              type="submit"
              disabled={!stripe || loading}
              className={`w-full bg-indigo-600 text-white py-3 rounded-full shadow hover:bg-indigo-500 transition mt-4 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
              style={{ backgroundColor: styleGuide?.primary }}
            >
              {loading ? 'Processing...' : 'Pay'}
            </button>

            <button
              type="button"
              onClick={() => setView('cart')}
              className="w-full text-indigo-600 py-3 rounded-full border border-indigo-600 shadow hover:bg-indigo-100 transition mt-4"
            >
              Back to Cart
            </button>

            {paymentRequest && (
              <div className="mt-4">
                <PaymentRequestButtonElement options={{ paymentRequest }} />
              </div>
            )}
          </form>
        )}
      </div>
    </div>
  );
}
