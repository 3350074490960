import React, { useState, useEffect } from 'react';
import { Navigate, Routes } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';

/* Templates */
import MobileMenu from '../../templates/MobileMenu/Index'
import DesktopSidebar from '../../templates/DesktopSidebar/Index';
import MobileHeader from '../../templates/MobileHeader/Index';

const PrivateRoute = ({ authenticated, redirectTo, children, onLogout, navigation }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [pendingMessage, setPendingMessage] = useState(null);

  const handleLogout = () => {
      onLogout();
  };

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      const authToken = Cookies.get('auth_token');
      if (authToken) {
        try {
          const response = await axios.get('/api/profile', {
            headers: {
              Authorization: `Token ${authToken}`,
            },
          });
          const { subscription } = response.data;
          console.log(subscription);
          if (subscription.status === 'pending') {
            setPendingMessage("Your subscription is pending approval. Please wait for further instructions.");
          }
        } catch (error) {
          console.error("Error checking subscription status:", error);
        }
      }
    };

    checkSubscriptionStatus();
  }, []);

  if (!authenticated) {
    return <Navigate to={redirectTo} />;
  }

  if (pendingMessage) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <div className="bg-white px-6 py-12 shadow-md rounded-lg max-w-md w-full sm:px-12">
          <p>{pendingMessage} <a href="/contact" className="font-semibold text-indigo-600 hover:text-indigo-500">Contact us</a></p>
        </div>
      </div>
    );
  }

  return (
    <div> 
      <MobileMenu isOpen={sidebarOpen} onLogout={handleLogout} onClose={() => setSidebarOpen(false)} navigation={navigation.navigation} externals={navigation.externals} />
      <DesktopSidebar navigation={navigation.navigation} externals={navigation.externals} onLogout={handleLogout} />
      <MobileHeader onMenuButtonClick={() => setSidebarOpen(true)} setSidebarOpen={setSidebarOpen} />
      <Routes>
        {children}
      </Routes>
    </div>
  );
};

export default PrivateRoute;