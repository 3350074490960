import React, { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { IconMap } from '../../../config/IconMap';

export default function ReusableModal({
  isOpen,
  closeModal,
  title,
  handleSave,
  isCategoryModal,
  isCreatingNewCategory,
  toggleMode,
  fieldValues,
  setFieldValues,
  categories,
}) {
  const cancelButtonRef = useRef(null);
  const [isSaving, setIsSaving] = useState(false);
  const [titleError, setTitleError] = useState('');
  const [variantErrors, setVariantErrors] = useState({
    sku: '',
    price: '',
    stock_quantity: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFieldValues((prev) => ({ ...prev, [name]: value }));
    if (name === 'title') {
      setTitleError('');
    } else {
      setVariantErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  const validateVariantFields = () => {
    const errors = {
      sku: !fieldValues.sku ? 'SKU is required' : '',
      price: !fieldValues.price ? 'Price is required' : '',
      stock_quantity: !fieldValues.stock_quantity ? 'Quantity is required' : ''
    };
    setVariantErrors(errors);
    return !Object.values(errors).some(x => x);
  };

  // Enable the save button only if conditions are met
  const canSave = isCategoryModal
    ? isCreatingNewCategory
      ? fieldValues.title && !titleError
      : true
    : fieldValues.sku && fieldValues.price && fieldValues.stock_quantity;

  // Save or create a category
  const saveCategory = async () => {
    if (isCreatingNewCategory) {
      if (!fieldValues.title) {
        setTitleError('Title is required.');
        return;
      }

      setIsSaving(true);
      try {
        const token = Cookies.get('auth_token');
        const response = await axios.post(
          '/api/categories',
          { title: fieldValues.title },
          { headers: { Authorization: `Token ${token}` } }
        );

        const newCategory = response.data;
        handleSave(newCategory);
        closeModal();
      } catch (error) {
        if (error.response && error.response.status === 400) {
          const errorMessage = error.response.data.title || 'An error occurred';
          setTitleError(errorMessage);
        } else {
          console.error('Failed to save category:', error);
        }
      } finally {
        setIsSaving(false);
      }
    } else {
      closeModal();
    }
  };

  // Delete category function
  const deleteCategory = async (categoryId) => {
    const confirmation = window.confirm("Are you sure you want to delete this category?");
    if (!confirmation) return;

    try {
      const token = Cookies.get('auth_token');
      await axios.delete(`/api/categories/${categoryId}`, {
        headers: { Authorization: `Token ${token}` },
      });
      // Update state to remove deleted category
      setFieldValues((prev) => prev.filter((id) => id !== categoryId));
    } catch (error) {
      console.error('Failed to delete category:', error);
    }
  };

  const handleAttributeChange = (index, key, value) => {
    setFieldValues((prev) => {
      const newAttributes = [...prev.attributes];
      newAttributes[index][key] = value;
      return { ...prev, attributes: newAttributes };
    });
  };

  const handleAddAttribute = () => {
    setFieldValues((prev) => ({
      ...prev,
      attributes: [...(prev.attributes || []), { title: '', value: '' }],
    }));
  };

  const handleRemoveAttribute = (index) => {
    setFieldValues((prev) => {
      const newAttributes = [...prev.attributes];
      newAttributes.splice(index, 1);
      return { ...prev, attributes: newAttributes };
    });
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">{title}</h3>

                  {isCategoryModal ? (
                    <>
                      <button onClick={toggleMode} className="block text-sm font-medium text-indigo-600 underline">
                        {isCreatingNewCategory ? 'Select an existing category' : 'Create a new category'}
                      </button>
                      {isCreatingNewCategory ? (
                        <div className="mt-4">
                          <label className="block text-sm font-medium text-gray-700">Title</label>
                          <input
                            type="text"
                            name="title"
                            value={fieldValues.title || ''}
                            onChange={handleInputChange}
                            className="mt-1 w-full p-2 border border-gray-300 rounded-md"
                          />
                          {titleError && <p className="text-sm text-red-600 mt-1">{titleError}</p>}
                        </div>
                      ) : (
                        <fieldset className="space-y-2 mt-4 px-4 py-4 border">
                          {categories.map((category) => (
                            <div key={category.id} className="relative flex items-center justify-between">
                              <div className="flex items-center">
                                <input
                                  type="checkbox"
                                  value={category.id}
                                  checked={fieldValues.includes(category.id)}
                                  onChange={(e) => {
                                    const selectedId = e.target.value;
                                    setFieldValues((prev) =>
                                      e.target.checked
                                        ? [...prev, selectedId]
                                        : prev.filter((id) => id !== selectedId)
                                    );
                                  }}
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                                <label className="ml-3 text-sm font-medium text-gray-900">{category.title}</label>
                              </div>
                              <button
                                onClick={() => deleteCategory(category.id)}
                                className="text-red-600 hover:text-red-800 text-sm font-medium flex items-center"
                              >
                                {React.createElement(IconMap['XMarkIcon'], { className: 'h-5 w-5', 'aria-hidden': 'true' })}
                                <span className="sr-only">Delete</span>
                              </button>
                            </div>
                          ))}
                        </fieldset>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">SKU</label>
                        <input
                          type="text"
                          name="sku"
                          value={fieldValues.sku || ''}
                          onChange={handleInputChange}
                          className={`mt-1 w-full p-2 border rounded-md ${
                            variantErrors.sku ? 'border-red-500' : 'border-gray-300'
                          }`}
                        />
                        {variantErrors.sku && <p className="text-sm text-red-600 mt-1">{variantErrors.sku}</p>}
                      </div>
                      <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">Price</label>
                        <input
                          type="number"
                          name="price"
                          value={fieldValues.price || ''}
                          onChange={handleInputChange}
                          className={`mt-1 w-full p-2 border rounded-md ${
                            variantErrors.price ? 'border-red-500' : 'border-gray-300'
                          }`}
                        />
                        {variantErrors.price && <p className="text-sm text-red-600 mt-1">{variantErrors.price}</p>}
                      </div>
                      <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">Stock Quantity</label>
                        <input
                          type="number"
                          name="stock_quantity"
                          value={fieldValues.stock_quantity || ''}
                          onChange={handleInputChange}
                          className={`mt-1 w-full p-2 border rounded-md ${
                            variantErrors.stock_quantity ? 'border-red-500' : 'border-gray-300'
                          }`}
                        />
                        {variantErrors.stock_quantity && <p className="text-sm text-red-600 mt-1">{variantErrors.stock_quantity}</p>}
                      </div>
                      <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">Attributes</label>
                        {fieldValues.attributes?.map((attr, index) => (
                          <div key={index} className="flex gap-2 mt-2">
                            <input
                              type="text"
                              placeholder="Title (e.g. Size)"
                              value={attr.title}
                              onChange={(e) => handleAttributeChange(index, 'title', e.target.value)}
                              className="mt-1 w-1/2 p-2 border border-gray-300 rounded-md"
                            />
                            <input
                              type="text"
                              placeholder="Value (e.g. Medium)"
                              value={attr.value}
                              onChange={(e) => handleAttributeChange(index, 'value', e.target.value)}
                              className="mt-1 w-1/2 p-2 border border-gray-300 rounded-md"
                            />
                            <button
                              type="button"
                              onClick={() => handleRemoveAttribute(index)}
                              className="text-red-600 hover:text-red-800"
                            >
                              Remove
                            </button>
                          </div>
                        ))}
                        <button
                          type="button"
                          onClick={handleAddAttribute}
                          className="mt-2 text-indigo-600 hover:text-indigo-800"
                        >
                          Add Attribute
                        </button>
                      </div>
                    </>
                  )}
                </div>

                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    onClick={() => {
                      if (isCategoryModal) {
                        saveCategory();
                      } else {
                        const isValid = validateVariantFields();
                        if (!isValid) {
                          // Don't proceed if validation fails
                          return;
                        }
                        handleSave(fieldValues);
                        closeModal();
                      }
                    }}
                    disabled={(isCategoryModal && isCreatingNewCategory && (!canSave || isSaving)) ||
                      (!isCategoryModal && (!fieldValues.sku || !fieldValues.price || !fieldValues.stock_quantity))}
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 sm:ml-3 sm:w-auto"
                  >
                    {isSaving ? 'Saving...' : 'Save'}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={closeModal}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
