import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { IconMap } from '../../config/IconMap';

const FormBoard = ({ columns, setColumns }) => {
    const [openEdit, setOpenEdit] = useState(null);
    const onDragEnd = (result) => {
        if (!result.destination || !result.source) return; // No valid drop source or destination
        const updatedColumns = [...columns];
        const sourceColumn = updatedColumns.find((col) => col.id === result.source.droppableId);
        const destColumn = updatedColumns.find((col) => col.id === result.destination.droppableId);
        if (!sourceColumn || !destColumn) return; // Invalid source or destination column
        const [field] = sourceColumn.questions.splice(result.source.index, 1);
        destColumn.questions.splice(result.destination.index, 0, field);
        setColumns(updatedColumns);
    };

    const createField = (columnId) => {
        const newField = { label: 'New Question', id: `field-${Date.now()}`, type: 'text-input' };
        setColumns(prevColumns => prevColumns.map(column => {
            if (column.id === columnId) {
                return { ...column, questions: [...column.questions, newField] };
            }
            return column;
        }));
    };

      const createColumn = () => {
        const newColumnId = columns.length + 1;
        const newColumn = {
            id: newColumnId,
            title: `Page ${columns.length + 1}`,
            questions: []
        };

        // Functional state update to add a new column
        setColumns(prevColumns => [...prevColumns, newColumn]);
    };

    const handleFieldChange = (columnId, fieldId, newLabel, newType) => {
        setColumns(prevColumns => prevColumns.map(column => {
            if (column.id === columnId) {
                return {
                    ...column,
                    questions: column.questions.map(field => {
                        if (field.id === fieldId) {
                            return { ...field, label: newLabel, type: newType };
                        }
                        return field;
                    })
                };
            }
            return column;
        }));
    };

    const deleteField = (columnId, fieldId) => {
        setColumns(prevColumns => prevColumns.map(column => {
            if (column.id === columnId) {
                return {
                    ...column,
                    questions: column.questions.filter(field => field.id !== fieldId)
                };
            }
            return column;
        }));
    };

    const handleTitleChange = (columnId, newTitle) => {
        const updatedColumns = columns.map((column) => {
            if (column.id === columnId) {
                return {
                    ...column,
                    title: newTitle,
                };
            }
            return column;
        });
        setColumns(updatedColumns);
    };

    const deleteColumn = (columnId) => {
        const updatedColumns = columns.filter((column) => column.id !== columnId);
        setColumns(updatedColumns);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className="grid grid-cols-2 gap-4 p-4">
                {columns.map((column, columnIndex) => (
                    <div key={column.id} className="col-span-3 sm:col-span-1 md:col-span-1 lg:col-span-1">
                        <div className="bg-gray-100 p-4 rounded-lg">
                            <div className="mb-4 flex items-center">
                                <input
                                    type="text"
                                    className="w-full block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={column.title}
                                    onChange={(e) => handleTitleChange(column.id, e.target.value)}
                                />
                                {/* Edit Page Button 
                                <button className="ml-2 rounded-md border-2 border-gray-300 hover:border-blue-500 hover:text-gray-700" onClick={() => setOpenEditPage(column.id === openEditPage ? null : column.id)}>
                                    {React.createElement(
                                        IconMap['Cog6ToothIcon'],
                                        { className: 'block h-6 w-6 text-gray-500', 'aria-hidden': 'true' }
                                    )}
                                </button> */}
                                {/* Delete Page Button */}
                                <button className="ml-2 rounded-md border-2 border-gray-300 hover:border-blue-500 hover:text-gray-700" onClick={() => deleteColumn(column.id)}>
                                    {React.createElement(
                                        IconMap['XMarkIcon'],
                                        { className: 'block h-6 w-6 text-gray-500', 'aria-hidden': 'true' }
                                    )}
                                </button>
                            </div>
                            {/* Conditionally render the sub-div for editing the page
                            {openEditPage === column.id && (
                                <div className="mb-4 bg-gray-200 p-2 rounded">

                                </div>
                            )} */}
                            <Droppable droppableId={column.id} key={column.id}>
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {column.questions.map((field, index) => (
                                            <Draggable key={field.id} draggableId={field.id} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className="flex flex-col rounded-md bg-gray-200 border-0 my-2 py-2 px-2"
                                                    >
                                                        <div className="flex items-center">
                                                            <input
                                                                type="text"
                                                                className="rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                value={field.label}
                                                                onChange={(e) => handleFieldChange(column.id, field.id, e.target.value, field.type)}
                                                            />
                                                            <select
                                                                value={field.type}
                                                                className="ml-2 rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                onChange={(e) => handleFieldChange(column.id, field.id, field.label, e.target.value)}
                                                            >
                                                                <option value="text-input">Text input</option>
                                                                <option value="text-area">Free text</option>
                                                                <option value="select-box">Options</option>
                                                            </select>
                                                            {/* Actions */}
                                                            <div>
                                                                {/* Edit Button */}
                                                                <button className="ml-2 rounded-md border-2 border-gray-300 hover:border-blue-500 hover:text-gray-700" onClick={() => setOpenEdit(field.id === openEdit ? null : field.id)}>
                                                                    {React.createElement(
                                                                        IconMap['Cog6ToothIcon'],
                                                                        { className: 'block h-6 w-6 text-gray-500', 'aria-hidden': 'true' }
                                                                    )}
                                                                </button>
                                                                {/* Delete Button */}
                                                                <button className="ml-2 rounded-md border-2 border-gray-300 hover:border-blue-500 hover:text-gray-700" onClick={() => deleteField(column.id, field.id)}>
                                                                    {React.createElement(
                                                                        IconMap['XMarkIcon'],
                                                                        { className: 'block h-6 w-6 text-gray-500 hover:text-gray-700', 'aria-hidden': 'true' }
                                                                    )}
                                                                </button>
                                                            </div>
                                                            <div className="ml-auto">
                                                                {/* Unclickable Icon */}
                                                                <div className="ml-4 mr-2">
                                                                    {React.createElement(
                                                                        IconMap['Bars2Icon'],
                                                                        { className: 'block h-6 w-6 text-gray-400', 'aria-hidden': 'true' }
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {openEdit === field.id && (
                                                            <div className="mt-2 bg-gray-100 p-2 rounded">
                                                                {/* Conditionally render content based on type */}
                                                                <label for="checkbox-group" className="block text-sm font-medium leading-6 text-gray-900 mb-2">
                                                                    Edit settings for this question
                                                                </label>
                                                                <div>
                                                                    <div id="checkbox-group" className="flex flex-row items-center space-x-4 w-full md:w-auto">
                                                                        <div className="flex items-center">
                                                                            <input type="checkbox" id="required" name="options" value="Required" className="form-checkbox h-5 w-5 text-blue-600" checked />
                                                                            <label for="required" className="ml-2 text-sm">Required</label>
                                                                        </div>
                                                                        <div className="flex items-center">
                                                                            <input type="checkbox" id="make-conditional" name="options" value="Make Conditional" className="form-checkbox h-5 w-5 text-blue-600" />
                                                                            <label for="make-conditional" className="ml-2 text-sm">Set condition</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {field.type === 'text-input' && (
                                                                    <div>
                                                                    </div>
                                                                )}
                                                                {field.type === 'text-area' && (
                                                                    <div>
                                                                        {/* Your settings for Text Area */}
                                                                    </div>
                                                                )}
                                                                {field.type === 'select-box' && (
                                                                    <div>
                                                                        {/* Your settings for Select Box */}
                                                                        {/* Maybe a control to add/edit/delete options */}
                                                                    </div>
                                                                )}
                                                                {/* ... (existing conditional renderings) */}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                            <button
                                className="mt-2 w-full rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                onClick={() => createField(column.id)}
                            >
                                Create question
                            </button>
                        </div>
                    </div>
                ))}
                <div className="col-span-3 sm:col-span-1 md:col-span-1 lg:col-span-1">
                    <button
                        className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={createColumn}
                    >
                        Create page
                    </button>
                </div>
            </div>
        </DragDropContext>
    );

};

export default FormBoard;
