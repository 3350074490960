import React from 'react';
import { IconMap } from "../../config/IconMap";

export default function MobileHeader({ onMenuButtonClick, setSidebarOpen }) {
    return (
        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white dark:bg-zinc-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
            <button type="button" className="-m-2.5 p-2.5 text-gray-700 dark:text-gray-100 lg:hidden" onClick={() => setSidebarOpen(true)}>
                <span className="sr-only">Open sidebar</span>
                {React.createElement(IconMap['Bars3BottomLeftIcon'], { className: 'h-6 w-6', 'aria-hidden': 'true' })}
            </button>
            <div className="flex-1 text-sm font-semibold leading-6 text-gray-900"></div>
        </div>
    );
  }
  