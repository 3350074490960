import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

import { QRCodeSVG } from 'qrcode.react';
import { IconMap } from '../config/IconMap';
import Cookies from 'js-cookie';

/* Components */
import Tabs from '../components/Tabs/Tabs';
import FormDetailEditView from '../components/Forms/FormDetailEditView';
import FormBoard from '../components/FormBoard/FormBoard';
import FormSettings from '../components/Forms/FormSettings';
import FormLayout from './FormLayout';
import Preview from '../components/PreviewAside/PreviewAside';

export default function FormEditor({ context }) {

    const { uid } = useParams();

    let baseURL;
    if (window.location.hostname === "localhost") {
        // If running on localhost, use a demo or local URL
        baseURL = "http://localhost:3000"; // or any other local URL you might have
    } else {
        // If not running on localhost, use the environment variable
        baseURL = process.env.REACT_APP_URL;
    }

    const previewURL = `${baseURL}/a/f/${uid}`;

    // States
    const [isAsideVisible, setIsAsideVisible] = useState(true);
    const [activeTab, setActiveTab] = useState(0);

    const [formTitle, setFormTitle] = useState('');
    const [formDescription, setFormDescription] = useState('');
    const [formSchema, setFormSchema] = useState('');

    const [columns, setColumns] = useState([]);
    const [changesMade, setChangesMade] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const [isPreviewPanelVisible, setIsPreviewPanelVisible] = useState(false);

    const tabs = [
        { name: 'Form fields', href: '#', current: activeTab === 0, onClick: () => handleTabClick(0) },
        { name: 'Settings', href: '#', current: activeTab === 1, onClick: () => handleTabClick(1) }
    ];

    // Handler to toggle preview panel visibility
    const togglePreviewPanel = () => {
        setIsPreviewPanelVisible(prev => !prev);
    };

    const handleTabChange = (newTabIndex) => {
        setActiveTab(newTabIndex);
    };

    const handleColumnsChange = (newColumns) => {
        setColumns(newColumns);
        setChangesMade(true);
    };

    const handlePublish = () => {
        const apiUrl = `/api/update-form/${uid}/`;
        const authToken = Cookies.get('auth_token');

        fetch(apiUrl, {
            method: 'PUT',
            headers: {
                'Authorization': `Token ${authToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                title: formTitle,
                description: formDescription,
                config: columns,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    setChangesMade(false);
                } else {
                    console.error('Failed to update form');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const toggleAside = () => {
        setIsAsideVisible(!isAsideVisible);
    };

    // Fetch data from API when the component mounts
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const response = await fetch(`/api/form/${uid}`);
                const data = await response.json();
                setFormSchema(data);
                setFormTitle(data.title);
                setFormDescription(data.description)
                setColumns((data.config));  // Transform formSchema to columns
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [uid]);

    return (
        <>
            <main className="lg:pl-72">
                <div className="xl:pr-96">
                    <div className="">
                        <Tabs
                            tabs={tabs}
                            activeTab={activeTab}
                            onTabChange={handleTabChange}
                            togglePreviewPanel={togglePreviewPanel}
                            isPreviewPanelVisible={isPreviewPanelVisible}
                        />
                        <div className="sm:w-full lg:w-3/4 mx-auto">
                            {activeTab === 0 && (
                                (!isLoading) && (
                                    <FormBoard columns={columns} setColumns={setColumns} />
                                )
                            )}
                            {activeTab === 1 && (
                                <div className="py-6">
                                    <div className="col-span-full px-6">
                                        <FormSettings />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Preview 
                <div className={`bg-gray-50 w-96 pt-6 overflow-y-auto sliding-panel ${isPreviewPanelVisible ? 'sliding-panel-visible' : ''}`}>
                    <div className="canvas mockup-phone border-primary shadow hover:shadow-md" style={{ margin: 1.25 + 'rem' }}>
                        <div className="camera"></div>
                        <div className="display bg-gray-50">
                            <div className="content artboard phone">
                                {(!isLoading) && (
                                    <FormLayout formTitle={formTitle} previewMode={true} config={columns} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>*/}

                <Preview
                    LayoutComponent={FormLayout}
                    previewMode={true}
                    config={columns}
                    assetTitle={formTitle}
                    assetDescription={formDescription}
                    isPreviewVisible={isPreviewPanelVisible}
                />

            </main>
            <div className="hidden xl:block fixed inset-y-0 right-0 bg-white border-gray-200 border-l">
                <aside className="w-96 overflow-y-auto">
                    <div className="mt-auto h-1/3 border-b border-gray-300">
                        <div className="col-span-full">
                            <div className="flex items-start flex-wrap bg-gray-50 px-6 py-6">
                                <div className="w-1/2">
                                    <QRCodeSVG value={previewURL} />
                                </div>
                                <div className="w-1/2">
                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                        Form URL
                                    </label>
                                    <p className="mt-3 text-sm leading-6 text-gray-600">This is the unique URL assigned to your page.</p>
                                    <div className="mt-2">
                                        <div>
                                            <Link target="_blank"
                                                to={previewURL}
                                                className="group flex items-center space-x-2.5 text-sm font-medium text-indigo-600 hover:text-indigo-900">
                                                {React.createElement(
                                                    IconMap['ArrowTopRightOnSquareIcon'], { className: 'h-5 w-5 text-indigo-500 group-hover:text-indigo-900', 'aria-hidden': 'true' }
                                                )}
                                                <span>Visit URL</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <FormDetailEditView formTitle={formTitle} setFormTitle={setFormTitle} formDescription={formDescription} setFormDescription={setFormDescription}/>
                        </div>
                        <div className="w-full">
                            <Link
                                onClick={changesMade ? handlePublish : null}
                                className={`flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 ${changesMade ? 'bg-green-500 text-white hover:bg-green-600 hover:text-white cursor-pointer' : 'bg-gray-300 text-gray-600 cursor-not-allowed'}`}
                            >
                                <span className="sr-only">Publish</span><span aria-hidden="true">Publish</span>
                            </Link>

                            <a className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50" href="/"><span className="sr-only">Current draft option: </span><span aria-hidden="true">Discard</span></a>
                        </div>
                    </div>
                </aside>

            </div>
        </>
    )
}