import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

import { QRCodeSVG } from 'qrcode.react';
import { IconMap } from '../config/IconMap';

/* Components */
import MdxEditorComponent from '../components/MDXEditor/MDXEditor';
import PageDetailEditView from '../components/Pages/PageDetailEditView';
import PageLayout from './PageLayout';
import BetaBanner from '../components/Banners/BetaBanner';
import Tabs from '../components/Tabs/Tabs';
import Preview from '../components/PreviewAside/PreviewAside';

export default function PageEditor({ context }) {

    const { uid } = useParams();
    
    let baseURL;
    if (window.location.hostname === "localhost") {
        baseURL = "http://localhost:3000"; 
    } else {
        baseURL = process.env.REACT_APP_URL;
    }
    const previewURL = `${baseURL}/a/p/${uid}`;

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    // State to manage the visibility of the aside
    const [isAsideVisible, setIsAsideVisible] = useState(true);
    const [content, setContent] = useState('');
    const [changesMade, setChangesMade] = useState(false);
    const [isPreviewPanelVisible, setIsPreviewPanelVisible] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    const tabs = [
        { name: 'Page editor', href: '#', current: activeTab === 0, onClick: () => handleTabClick(0) },
    ];

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    // Initialize the state for the display attributes like Page title and description
    const [displayAttributes, setDisplayAttributes] = useState({
        pageTitle: '',
        pageDescription: '',
        actionEnabled: false,
        actionDescription: '',
        actionStr: '',
    });

    let configForPreview = {
        pageTitle: displayAttributes.pageTitle,
        pageDescription: displayAttributes.pageDescription,
        markdownContent: content,
        actionEnabled: displayAttributes.actionEnabled,
        actionDescription: displayAttributes.actionDescription,
        actionStr: displayAttributes.actionStr,
    };

    const handleContentChange = (newContent) => {
        setContent(newContent);
        setChangesMade(true);
    };

    const handleDisplayAttributesChange = (newAttributes) => {
        setDisplayAttributes(prevAttributes => ({
            ...prevAttributes,
            ...newAttributes
        }));
        setChangesMade(true);
    };

    const handleActionEnabledChange = (enabled) => {
        setDisplayAttributes({...displayAttributes, actionEnabled: enabled});
        setChangesMade(true);
    };

    const handleActionStrChange = (str) => {
        setDisplayAttributes({...displayAttributes, actionStr: str});
        setChangesMade(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true); // Start loading
                const response = await fetch(`/api/page/${uid}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setDisplayAttributes({
                    pageTitle: data.title || '', // Fallback to empty string if undefined
                    pageDescription: data.description || '',
                    actionEnabled: typeof data.action_enabled === 'boolean' ? data.action_enabled : false, 
                    actionDescription: data.action_description || '',
                    actionStr: data.action_str || ''
                });
                if (Array.isArray(data.config)) {
                    // Transform the array of {value: "text"} objects into a single string
                    // with two newlines between each value for a paragraph break
                    const contentString = data.config.map(item => item.value).join('\n\n');
                    setContent(contentString);
                }
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false); // End loading
            }
        };

        fetchData();
    }, [uid]);

    const toggleAside = () => {
        setIsAsideVisible(!isAsideVisible);
    };

    // Handler to toggle preview panel visibility
    const togglePreviewPanel = () => {
        setIsPreviewPanelVisible(prev => !prev);
    };
    
    const handleTabChange = (newTabIndex) => {
        setActiveTab(newTabIndex);
    };

    const handlePublish = () => {
        const apiUrl = `/api/update-page/${uid}/`;
        const authToken = Cookies.get('auth_token');

        // Splitting the content into paragraphs and transforming it into the required format
        const contentArray = content.split('\n').filter(paragraph => paragraph.trim() !== '').map(paragraph => ({ value: paragraph }));

        fetch(apiUrl, {
            method: 'PUT',
            headers: {
                'Authorization': `Token ${authToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                title: displayAttributes.pageTitle,
                description: displayAttributes.pageDescription,
                config: contentArray,
                action_enabled: displayAttributes.actionEnabled,
                action_description: displayAttributes.actionDescription,
                action_str: displayAttributes.actionStr,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    setChangesMade(false);
                } else {
                    console.error('Failed to update page');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    return (
        <>
            <main className="lg:pl-72">
                <div className="xl:pr-96">

                <div className="">
                        <Tabs 
                            tabs={tabs} 
                            activeTab={activeTab} 
                            onTabChange={handleTabChange} 
                            togglePreviewPanel={togglePreviewPanel}
                            isPreviewPanelVisible={isPreviewPanelVisible}
                        />
                        <div className="sm:w-full lg:w-3/4 mx-auto">
                            {activeTab === 0 && (
                                (!isLoading) && (
                                    <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 mx-4 my-4 bg-white border-gray-200 border rounded-md">
                                    {/* Render MdxEditorComponent only when not loading */}
                                    {!isLoading && (
                                        <MdxEditorComponent content={content} setContent={handleContentChange} />
                                    )}
                                    <BetaBanner />
                                </div>
                                )
                            )}
                        </div>
                    </div>

                </div>
            </main>
            
            {/* Preview */}
            <Preview
                    LayoutComponent={PageLayout}
                    previewMode={true}
                    config={configForPreview}
                    assetTitle=""
                    isPreviewVisible={isPreviewPanelVisible}
                />

            <div className="hidden xl:block fixed inset-y-0 right-0 bg-white border-gray-200 border-l">

                <aside className="w-96 overflow-y-auto">
                    <div className="mt-auto h-1/3 border-b border-gray-300">
                        <div className="col-span-full">
                            <div className="flex items-start flex-wrap bg-gray-50 px-6 py-6">
                                <div className="w-1/2">
                                    <QRCodeSVG value={previewURL} />
                                </div>
                                <div className="w-1/2">
                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                        Page URL
                                    </label>
                                    <p className="mt-3 text-sm leading-6 text-gray-600">This is the unique URL assigned to your page.</p>
                                    <div className="mt-2">
                                        <div>
                                            <Link target="_blank"
                                                to={previewURL}
                                                className="group flex items-center space-x-2.5 text-sm font-medium text-indigo-600 hover:text-indigo-900">
                                                {React.createElement(
                                                    IconMap['ArrowTopRightOnSquareIcon'], { className: 'h-5 w-5 text-indigo-500 group-hover:text-indigo-900', 'aria-hidden': 'true' }
                                                )}
                                                <span>Visit URL</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <PageDetailEditView
                                displayAttributes={displayAttributes}
                                setDisplayAttributes={setDisplayAttributes}
                                setActionEnabled={handleActionEnabledChange}
                                setActionStr={handleActionStrChange}
                                currentUid={uid}
                            />
                        </div>
                        <div className="w-full">
                            <Link
                                onClick={changesMade ? handlePublish : null}
                                className={`flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 ${changesMade ? 'bg-green-500 text-white hover:bg-green-600 hover:text-white cursor-pointer' : 'bg-gray-300 text-gray-600 cursor-not-allowed'}`}
                            >
                                <span className="sr-only">Publish</span><span aria-hidden="true">Publish</span>
                            </Link>
                            <a className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50" href="/"><span className="sr-only">Current draft option: </span><span aria-hidden="true">Discard</span></a>
                        </div>
                    </div>
                </aside>
            </div>
        </>
    )
}
